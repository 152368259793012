import * as React from 'react'
import { Global } from '@emotion/react'

import Seo from './Seo'

const Layout = ({ children, className }) => {
  return (
    // eslint-disable-next-line
    <React.Fragment>
      <Global
        styles={(theme) => ({
          '*': {
            boxSizing: 'inherit',
            '&:before': {
              boxSizing: 'inherit'
            },
            '&:after': {
              boxSizing: 'inherit'
            }
          },
          html: {
            fontSize: '18px',
            WebkitTextSizeAdjust: '100%'
          },
          img: {
            borderStyle: 'none'
          },
          pre: {
            fontFamily: 'monospace',
            fontSize: '1em'
          },
          '[hidden]': {
            display: 'none'
          },
          '::selection': {
            backgroundColor: theme.colors.primary,
            color: theme.colors.background
          }
        })}
      />
      <Seo />
      <main className={className}>{children}</main>
    </React.Fragment>
  )
}

export default Layout
